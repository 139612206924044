import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../config/constants';
import { prepareHeaders } from './coins';
import { createQueryString } from '../config/functions';

export const reportApi = createApi({
    reducerPath: 'reportApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL + "/report", prepareHeaders }),
    tagTypes: ['Reports'],

    endpoints: (builder) => ({

        getPayoutReport: builder.query({
            query: (query) => `/payout/?${createQueryString(query)}`,
            transformResponse: (response) => response?.data
        }),

        
        getPurchasedReport: builder.query({
            query: (query) => `/purchased/?${createQueryString(query)}`,
            transformResponse: (response) => response?.data
        }),



        getApprovedCoinsReport: builder.query({
            query: (query) => `/coins/?${createQueryString(query)}`,
            transformResponse: (response) => response?.data
        }),


        getGraphReport: builder.query({
            query: (query = {}) => `?${createQueryString(query)}`,
            transformResponse: (response) => response?.data
        }),


        getTopUsers: builder.query({
            query: (query ) => `/top-users?${createQueryString(query)}`,
            transformResponse: (response) => response?.data
        }),

        getTopRedCoins: builder.query({
            query: (query) => `/top-red-coins?${createQueryString(query)}`,
            transformResponse: (response) => response?.data
        }),



    }),
});

export const { useGetPayoutReportQuery , useGetApprovedCoinsReportQuery,useGetPurchasedReportQuery ,useGetGraphReportQuery ,useGetTopUsersQuery,useGetTopRedCoinsQuery} = reportApi;
