import {
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Popover,
  Row,
  Select, Tabs
} from 'antd';
import dayjs from "dayjs";
import { useState } from 'react';
import { FaFilter, FaSearch } from "react-icons/fa";
import { useGetTopRedCoinsQuery, useGetTopUsersQuery } from '../../api/reports';
import avatar from "../../assets/avatar.png";
import { UPLOADS_URL2 } from '../../config/constants';



const getDateRange = (filterType) => {
  let startDate, endDate;
  switch (filterType) {
    case 'All':
        startDate = null;
        endDate = null;
        break;

    case 'Today':
        startDate = dayjs().startOf('day');
        endDate = dayjs().endOf('day');
        break;
        
    case 'This Week':
        startDate = dayjs().startOf('week');
        endDate = dayjs().endOf('week');
        break;
        
    case 'This Month':
        startDate = dayjs().startOf('month');
        endDate = dayjs().endOf('month');
        break;
        
    case 'This Year':
        startDate = dayjs().startOf('year');
        endDate = dayjs().endOf('year');
        break;
        
    default:
        throw new Error('Invalid filter type');
}
  return {
      startDate,
      endDate,
  };
};


export default function TopUsers() {

  const [open, setOpen] = useState(false);
  const [activeTab,setActiveTab]= useState("Top Users")
  

  const [filter, setFilter] = useState({
    status: null,
    keyword: "",
    startDate: null,
    endDate: null,
  });

  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });




  
  const {data,refetch:refetchTopRedCoins} = useGetTopRedCoinsQuery({...filter})
  // console.log("🚀 ~ data of top red Coins", data)
  
  const {data:topUsers,refetch:refetchTopUsers} = useGetTopUsersQuery({...filter})

  function refetch(){
    refetchTopUsers()
    refetchTopRedCoins()
  }


  const handleSearch = (value) => {
    setFilter({
      ...filter,
      keyword: value,
    });
  };

  const resetFilter = () => {
    setFilter({
      status: null,
      keyword: "",
      startDate: null,
      endDate: null,
    });
    refetch();
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleFrom = (date) => {
    setFilter({
      ...filter,
      startDate: date,
    });
  };

  const handleTo = (date) => {
    setFilter({
      ...filter,
      endDate: date,
    });
  };

  const handleLimitChange = (pageSize) => {
    setPaginationConfig({
      ...paginationConfig,
      limit: pageSize,
      current: 1,
    });

    refetch();
  };
  function handleDateChane(e){
    let res=  getDateRange(e)
    // console.log("check for null",res)
    setFilter({
      ...filter,
      startDate: res.startDate,
      endDate: res.endDate,
    });
  }



    const onChange1 = (key) => {
      setActiveTab(key);
    };

    const tabs = [
      {
        key:1,
        label : "Top Users"
      },
      {
        key:2,
        label : "Top Red Coins"
      }
    ]


    const filterContent = (
      <div className="filterDropdown">
        <div>
          <p className="mainLabel" style={{ padding: "10px" }}>
            Filter
          </p>
        </div>
        <hr style={{ margin: 0 }} />
  
        <div className="filterDropdownBody">
          <p className="mainLabel">Creation Date:</p>
          <DatePicker
            className="mainInput filterInput"
            value={filter?.startDate}
            onChange={(e) => handleFrom(e)}
          />
          <DatePicker
            className="mainInput filterInput"
            value={filter.endDate}
            onChange={(e) => handleTo(e)}
          />
  
          <p className="mainLabel">Filter by Status:</p>
  
  
  
          <Button
            type="primary"
            shape="round"
            block
            size={"large"}
            style={{ marginBottom: "10px" }}
            className="mainButton primaryButton"
            onClick={() => refetch()}
          >
            Apply
          </Button>
          <Button
            type="primary"
            shape="round"
            block
            size={"large"}
            className="mainButton primaryButton2"
            onClick={() => resetFilter()}
          >
            Clear All
          </Button>
        </div>
      </div>
    );


  return (
        <Layout className="configuration">

      <Tabs
      onChange={onChange1}
      type="card"
      items={tabs.map((tab) => {
        return {
          label: `${tab.label}`,
          key: tab.label,
          // children: `Content of Tab Pane ${tab.key}`,
        };
      })}
    />

      <div className="boxDetails">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <h1 className="pageTitle">Payout Reports</h1>
          </Col>
          <Col
            xs={24}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >

          {data?.totalAmount &&
          <div className="flex items-center gap-5 text-2xl font-semibold text-black">
          <p>Total Amount </p>
          <p>$ {data?.totalAmount}</p>
          </div>
          }



          </Col>
        </Row>


        <Row style={{ padding: "10px 20px" }}>
          <Col xs={24} md={12}>

          <Row className="flex items-center gap-7 text-black">
          <div>
          <h5 style={{ display: "inline", fontSize: 16 }}>Show : </h5>
            <Select
              size={"large"}
              className="chartSelectBox"
              defaultValue={paginationConfig.limit}
              onChange={(e) => handleLimitChange(e)}
              style={{
                width: 70,
                textAlign: "left",
              }}
              options={[
                { value: 10, label: "10" },
                { value: 20, label: "20" },
                { value: 30, label: "30" },
                { value: 40, label: "40" },
                { value: 50, label: "50" },
              ]}
            />
            &emsp;
            <h5 style={{ display: "inline", fontSize: 16 }}>Entries</h5>
          </div>


          <div>
          <h5 style={{ display: "inline", fontSize: 16 }}>Select : </h5>
            <Select
              size={"large"}
              className="chartSelectBox"
              defaultValue={"Week"}
              onChange={(e) => handleDateChane(e)}
              style={{
                width: 120,
                textAlign: "left",
              }}
              options={[
                { value: "Today", label: "Today" },
                { value: "This Week", label: "This Week" },
                { value: "This Month" , label: "This Month" },
                { value: "This Year", label: "This Year" },
              ]}
            />
            &emsp;
            <h5 style={{ display: "inline", fontSize: 16 }}>Date</h5>
          </div>
          </Row>

         
          </Col>





          <Col
            xs={24}
            md={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Input
              style={{ width: "250px" }}
              className="mainInput dashInput"
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
              suffix={
                <FaSearch
                  style={{
                    color: "#3c5a92",
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    refetch()
                  }
                />
              }
              onPressEnter={(e) =>
                refetch()
              }
            />
            &emsp;
            <Popover
              content={filterContent}
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
              placement="bottomRight"
              arrow={false}
            >
              <Button
                style={{
                  padding: "10px 15px",
                  height: "auto",
                  // backgroundColor: "#3c5a92",
                }}
                className="fltr-btn"
              >
                <FaFilter style={{ fontSize: "16px", color: "white" }} />
              </Button>
            </Popover>
          </Col>
        </Row>

        <Row>
                
      



      <div className={`${activeTab !== "Top Users" && "hidden"} flex flex-col gap-8 mt-4 w-full`}>
          {topUsers?.docs?.length > 0 ? 
            topUsers.docs.map((data)=>{
            return <Cards data={data}/>
            })
            :
            <p className='text-center w-full text-black text-2xl'>No User Found</p>
            }
      </div>



      <div className={`${activeTab !== "Top Red Coins" && "hidden"} flex flex-col gap-8 mt-4 w-full`}>
        {data?.docs?.length > 0 ?
        data.docs.map((data)=>{
        return <Cards data={data}/>
        })
        :
        <p className='text-center w-full text-black text-2xl'>No User Found</p>
        }
      </div>  

        </Row>

      </div>
          </Layout> 
 )}



const Cards = ({data})=>{
  // console.log(data)
return(
  <article className="rounded-xl border border-gray-700 bg-[#121a1d] p-4">
  <div className="flex items-center gap-4">
    <img
      alt=""
      src={data?.image ? UPLOADS_URL2 + data.image : avatar}
      className="size-16 rounded-full object-cover"
    />

    <div className='flex flex-col items-start'>
      <h3 className="text-lg font-medium text-white">{data?.firstName +" "+ data?.lastName}</h3>
      <p className="text-sm font-medium text-white">{data?.email}</p>
    </div>
  </div>

  <ul className="mt-4 space-y-2">
    <li>
      <p  className="block h-full rounded-lg border border-gray-700 p-4 hover:border-pink-600">
        <strong className="font-medium text-white">  {data?.requestCount ? "Request Count" : data?.orderCount? "Order Count" : "" } </strong>

        <p className="mt-1 text-xs font-medium text-gray-300">
          {data?.requestCount? data?.requestCount : data?.orderCount?? ""}
        </p>
      </p>
    </li>

    <li>
      <p  className="block h-full rounded-lg border border-gray-700 p-4 hover:border-pink-600">
        <strong className="font-medium text-white">{data?.totalRedCoins ? "Total Red Coins" : data?.totalSpent? "Total Spent" : "" }</strong>

        <p className="mt-1 text-xs font-medium text-gray-300">
          {data?.totalRedCoins? data?.totalRedCoins : data?.totalSpent ?? ""}
        </p>
      </p>
    </li>
  </ul>
</article>
)

}
